export default [
  {
    path: '/order/amazon/preAlert',
    name: 'pre-alert',
    component: resolve => require(['@/views/order/amazon/preAlert.vue'], resolve),
    // component: () => import('@/views/order/amazon/preAlert.vue'),
    // meta: {
    //   breadcrumb: [
    //     {
    //       text: 'Amazon Order',
    //     },
    //     {
    //       text: 'Pre Alert',
    //       active: true,
    //     },
    //   ],
    // },
  },
  {
    path: '/order/amazon/preAlert/addMawb',
    name: 'add-mawb',
    component: () => import('@/views/order/amazon/components/addMawb.vue'),
    // meta: {
    //   breadcrumb: [
    //     {
    //       text: 'Pre Alert',
    //       to: '/order/amazon/preAlert',
    //     },
    //     {
    //       text: 'addMawb',
    //       active: true,
    //     },
    //   ],
    // },
  },
  {
    path: '/order/amazon/preAlert/mawbDetails/:id',
    name: 'mawb-details',
    component: () => import('@/views/order/amazon/components/mawbDetails.vue'),
    // meta: {
    //   breadcrumb: [
    //     {
    //       text: 'Pre Alert',
    //       to: '/order/amazon/preAlert',
    //     },
    //     {
    //       text: 'mawbDetails',
    //       active: true,
    //     },
    //   ],
    // },
  },
  {
    path: '/order/amazon/order',
    name: 'amazon-job',
    component: () => import('@/views/order/amazon/job.vue'),
    // meta: {
    //   breadcrumb: [
    //     {
    //       text: 'Amazon Job',
    //       active: true,
    //     },
    //   ],
    // },
  },
  {
    path: '/order/amazon/order/jobDetails/:id/:orderId/',
    name: 'job-details',
    component: () => import('@/views/order/amazon/components/jobDetails.vue'),
    // meta: {
    //   breadcrumb: [
    //     {
    //       text: 'Job',
    //       to: '/order/amazon/job',
    //     },
    //     {
    //       text: 'jobDetails',
    //       active: true,
    //     },
    //   ],
    // },
  },
  {
    path: '/order/amazon/return',
    name: 'amazon-return',
    component: () => import('@/views/order/amazon/return.vue'),
  },
  {
    path: '/order/fulfillment/inbound',
    name: 'inbound',
    component: () => import('@/views/order/fulfillment/inbound.vue'),
  },
  {
    path: '/order/fulfillment/outbound',
    name: 'outbound',
    component: () => import('@/views/order/fulfillment/outbound.vue'),
  },
  {
    path: '/order/fulfillment/inboundDetails',
    name: 'inbound-detail',
    component: () => import('@/views/order/fulfillment/components/inboundDetails.vue'),
  },
  {
    path: '/order/fulfillment/outboundDetails',
    name: 'outbound-detail',
    component: () => import('@/views/order/fulfillment/components/outboundDetails.vue'),
  },
  {
    path: '/order/fulfillment/skuMaster',
    name: 'skuMaster',
    component: () => import('@/views/order/fulfillment/skuMaster.vue'),
  },
  {
    path: '/order/fulfillment/skuMaster/skuDetails/:id',
    name: 'sku-detail',
    component: () => import('@/views/order/fulfillment/components/skuDetails.vue'),
  },
  {
    path: '/order/lastMile/codFodList',
    name: 'cod-fod-list',
    component: () => import('@/views/order/lastMile/codFodList.vue'),
  },
  {
    path: '/order/lastMile/orderList2',
    name: 'orderList',
    component: () => import('@/views/order/lastMile/orderList.vue'),
  },
  {
    path: '/order/lastMile/orderList',
    name: 'orderList2',
    component: () => import('@/views/order/lastMile/orderList2.vue'),
  },
  {
    path: '/order/lastMile/orderList/createOrder',
    name: 'create-order',
    component: () => import('@/views/order/lastMile/components/createOrder.vue'),
  },
  {
    path: '/order/lastMile/orderList/intermediateTip',
    name: 'intermediate-tip',
    component: () => import('@/views/order/lastMile/components/intermediateTip.vue'),
  },
  {
    path: '/order/lastMile/orderList/returnOrder',
    name: 'return-order',
    component: () => import('@/views/order/lastMile/components/returnOrder.vue'),
  },
  {
    path: '/order/lastMile/pickupRequest',
    name: 'pickup-request',
    component: () => import('@/views/order/lastMile/pickupRequest.vue'),
  },
  {
    path: '/order/lastMile/pickupRequest/createPickup',
    name: 'create-pickup',
    component: () => import('@/views/order/lastMile/components/createPickup.vue'),
  },
  {
    path: '/order/lastMile/RegularPickup',
    name: 'regular-pickup',
    component: () => import('@/views/order/lastMile/RegularPickup.vue'),
  },
  {
    path: '/order/lastMile/RegularPickup/createRegularPickup',
    name: 'create-regular-pickup',
    component: () => import('@/views/order/lastMile/components/createRegularPickup.vue'),
  },
  {
    path: '/order/lastMile/uploadOrder',
    name: 'upload-order',
    component: () => import('@/views/order/lastMile/uploadOrder.vue'),
  },
  {
    path: '/order/operation/index',
    name: 'operation',
    component: () => import('@/views/order/operation/index.vue'),
  },
  {
    path: '/order/operation/batchUpdate',
    name: 'batch-update',
    component: () => import('@/views/order/operation/batchUpdate.vue'),
  },
  {
    path: '/order/operation/cusOrder',
    name: 'cus-order-update',
    component: () => import('@/views/order/operation/cusOrder.vue'),
  },
  {
    path: '/order/report/inboundReport',
    name: 'inbound-report',
    component: () => import('@/views/order/report/inboundReport.vue'),
  },
  {
    path: '/order/report/statusReport',
    name: 'status-report',
    component: () => import('@/views/order/report/statusReport.vue'),
  },
  {
    path: '/order/report/batchDownload',
    name: 'batch-download',
    component: () => import('@/views/order/report/batchDownload.vue'),
  },
  {
    path: '/order/report/batchDownloadSignature',
    name: 'batch-download-signature',
    component: () => import('@/views/order/report/batchDownloadSignature.vue'),
  },
  {
    path: '/order/report/downloadReport',
    name: 'download-report',
    component: () => import('@/views/order/report/downloadReport.vue'),
  },
  {
    path: '/order/report/downloadInvoice',
    name: 'download-invoice',
    component: () => import('@/views/order/report/downloadInvoice.vue'),
  },
  {
    path: '/order/fulfillment/salesOrder',
    name: 'salesOrder',
    component: () => import('@/views/order/fulfillment/salesOrder.vue'),
  },
  {
    path: '/order/fulfillment/createSalesOrder',
    name: 'create-salesOrder',
    component: () => import('@/views/order/fulfillment/components/createSalesOrder.vue'),
  },
  {
    path: '/order/fulfillment/salesDetails',
    name: 'salesOrder-detail',
    component: () => import('@/views/order/fulfillment/components/salesDetails.vue'),
  },
  {
    path: '/order/fulfillment/asn',
    name: 'asn',
    component: () => import('@/views/order/fulfillment/asn.vue'),
  },
  {
    path: '/order/fulfillment/inventoryListing',
    name: 'inventory',
    component: () => import('@/views/order/fulfillment/inventory.vue'),
  },
  {
    path: '/order/lastMile/PY-Waybill',
    name: 'PY-Waybill',
    component: () => import('@/views/order/lastMile/PY-Waybill.vue'),
  },
  {
    path: '/order/lastMile/PY-Waybill/createMapping',
    name: 'create-mapping',
    component: () => import('@/views/order/lastMile/components/createMapping.vue'),
  },
  {
    path: '/order/lastMile/PY-Waybill/searchCustomer',
    name: 'search-customer',
    component: () => import('@/views/order/lastMile/components/searchCustomer.vue'),
  },
]
