import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
// import { canNavigate } from '@/libs/acl/routeProtection'
// import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
// import { isUserLoggedIn } from '@/auth/utils'
import dashboard from './routes/dashboard'
import pages from './routes/pages'
import orderManagement from './routes/order-management'
import trackTrace from './routes/track-trace'
import configCenter from './routes/config-center'
import businessProfile from './routes/business-profile'
import billing from './routes/billing'
import log from './routes/log'

Vue.use(VueRouter)

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'auth-login' } },
    ...dashboard,
    ...orderManagement,
    ...pages,
    ...trackTrace,
    ...configCenter,
    ...businessProfile,
    ...billing,
    ...log,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// router.beforeEach((to, _, next) => {
//   const isLoggedIn = isUserLoggedIn()

//   if (!canNavigate(to)) {
//     // Redirect to login if not logged in
//     if (!isLoggedIn) return next({ name: 'auth-login' })

//     // If logged in => not authorized
//     return next({ name: 'pre-alert' })
//   }

//   // Redirect if logged in
//   // if (to.meta.redirectIfLoggedIn && isLoggedIn) {
//   //   const userData = getUserData()
//   //   next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
//   // }

//   return next()
// })
router.beforeEach((to, from, next) => {
  sessionStorage.getItem('token')
  localStorage.getItem('userInfo')
  next()
})

export default router
