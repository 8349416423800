import { Ability } from '@casl/ability'
import { initialAbility } from './config'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const userData = JSON.parse(sessionStorage.getItem('userInfo'))
// const existingAbility = userData ? userData.ability : null
// console.log(userData)
const arr1 = userData ? userData.roleDtos : null
const arr = []
if ( arr1 !== null) {
  arr1.map(i =>{
    const obj = {}
    obj.action = i.name
    arr.push(obj)
  })
}
// console.log(arr)
export default new Ability(arr)
