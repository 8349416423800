export default [
    {
      path: '/log/apiRequestErrorLog',
      name: 'api-request-error-log',
      component: () => import('@/views/log/apiRequestErrorLog.vue'),
    },
    {
      path: '/log/SFOneStopMonitor',
      name: 'sf-one-stop-monitor',
      component: () => import('@/views/log/SFOneStopMonitor.vue'),
    },
      {
      path: '/log/ProblemOrderCallLog',
      name: 'problem-order-call-log',
      component: () => import('@/views/order/lastMile/problemCallLog.vue'),
    },
    
  ]
  