export default [
  {
    path: '/business-profile/partner/partnerList',
    name: 'partner-list',
    component: () => import('@/views/business-profile/partner/partnerList.vue'),
  },
  {
    path: '/business-profile/customer/customerList',
    name: 'customer-list',
    component: () => import('@/views/business-profile/customer/customerList.vue'),
  },
  {
    path: '/business-profile/customer/customerList2',
    name: 'customer-list2',
    component: () => import('@/views/business-profile/customer/customerList2.vue'),
  },
  {
    path: '/business-profile/customer/createCustomer',
    name: 'create-customer',
    component: () => import('@/views/business-profile/customer/components/createCustomer.vue'),
  },
  {
    path: '/business-profile/customer/customerDetail2/:id',
    name: 'customer-detail2',
    component: () => import('@/views/business-profile/customer/components/customerDetail2.vue'),
  },
  {
    path: '/business-profile/customer/customerDetails/:id',
    name: 'customer-details',
    component: () => import('@/views/business-profile/customer/components/customerDetail.vue'),
  },
  {
    path: '/business-profile/merchantLabel/merchantLabel',
    name: 'merchant-label',
    component: () => import('@/views/business-profile/merchantLabel/merchantLabel.vue'),
  },
]
