export default [
  {
    path: '/billing/invoiceList',
    name: 'invoice-list',
    component: () => import('@/views/billing/invoiceList.vue'),
  },
  {
    path: '/billing/components/invoiceDetail/:pid/:id',
    name: 'invoice-detail',
    component: () => import('@/views/billing/components/invoiceDetail.vue'),
  },
  {
    path: '/billing/charge',
    name: 'charge-table',
    component: () => import('@/views/billing/charge.vue'),
  },
  {
    path: '/billing/charge/createCharge',
    name: 'add-charge',
    component: () => import('@/views/billing/components/addCharge.vue'),
  },
  {
    path: '/billing/charge/chargeDetail/:id',
    name: 'charge-detail',
    component: () => import('@/views/billing/components/chargeDetail.vue'),
  },
]
