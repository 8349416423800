export default [
  {
    path: '/track',
    name: 'track',
    component: () => import('@/views/track-trace/index.vue'),
    meta: {
      layout: 'full',
    },
  },
]
